//Home.js
import './Home.css';
import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import logo from "../../logo.svg"
import TextScramble from '../TextScramble';


function Home() {
    const [activeLink, setActiveLink] = useState("");
   
  
    const handleClick = (page) => {
      setActiveLink(page);
    }


  return (
    <div className='main-hero'>
 
        <div className='home-hero '>

          <div className='home-left poppins-'>
            {/* <div className={`shiny-button home_title ${activeLink === "Designer" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("Designer")} className='navbar__link' to="/designer">Designer</Link>
            </div> */}

            {/* <div className={`shiny-button home_title ${activeLink === "Deveopler" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("Deveopler")} className='navbar__link' to="/deveopler">developer</Link>
            </div> */}

                 {/* <div className={`shiny-button home_title ${activeLink === "Illustrator" ? 'navbar__item--clicked' : ''}`}>
              <Link onClick={() => handleClick("Illustrator")} className='navbar__link' to="/illustrator">Illustrator</Link>
            </div> */}

            <div className={`shiny-button home_title ${activeLink === "Designer" ? 'navbar__item--clicked' : ''}`} 
                  onClick={() => {
                    handleClick("Designer"); 
                    document.getElementById("temp")?.scrollIntoView({ behavior: "smooth" });}}> Designer</div>
            
            <div className={`shiny-button home_title ${activeLink === "Deveopler" ? 'navbar__item--clicked' : ''}`} 
                  onClick={() => {
                    handleClick("Deveopler"); 
                    document.getElementById("temp")?.scrollIntoView({ behavior: "smooth" });}}> developer</div>

            <div className={`shiny-button home_title ${activeLink === "Illustrator" ? 'navbar__item--clicked' : ''}`} 
                  onClick={() => {
                    handleClick("Illustrator"); 
                    document.getElementById("temp")?.scrollIntoView({ behavior: "smooth" });}}> Illustrator</div>

          </div>

          <div className='home-right'>
            Transforming ideas into reality through code, design, and illustration. 
            <div className='strong shiny-button'>
              <a href='../contact'>
              <TextScramble text='Ready for the next project!' />
              {/* Ready for the next project! */}
              </a>
            </div>
          </div>
        </div>
 
        <div className='second-div'>
          {/* <div><a href='./'>CALL TO ACTION</a></div> */}
        </div>
        <div className='third-div'>
         
          <img id='temp' className='third-div-logo' src={logo} alt="logo" />

          <div className='third-div-title'><div>This site is currently under construction, but check back soon for a fresh new look and exciting updates.</div></div>

        </div>


    </div>


  );
}

export default Home;

